import React from 'react'

import Timeline from '@mui/lab/Timeline';
import ProgressItem from '../ProgressItem.js'


import img0630 from '../images/TimelinePhotos/6_30 Picking.jpg' 
import img0715 from '../images/TimelinePhotos/7_15 Picking.jpg'
import img0806 from '../images/TimelinePhotos/8_6 Picking.jpg' 
import img0812 from '../images/TimelinePhotos/8_12 Picking.jpg' 
import img08122 from '../images/TimelinePhotos/8_12 Picking 2.jpg'
import img0814 from '../images/TimelinePhotos/8_14 Picking.jpg'  
import img0908 from '../images/TimelinePhotos/9_8 Picking.jpg' 
import img1013 from '../images/TimelinePhotos/10_13 Picking.jpg' 
import img1111 from '../images/TimelinePhotos/11_11 Picking.jpg' 
import img1114 from '../images/TimelinePhotos/11_14 Magazine.jpg' 
import img1223 from '../images/TimelinePhotos/12_23 Picking.jpg' 
import img1227 from '../images/TimelinePhotos/12_27 Picking.jpg' 
import img1325 from '../images/TimelinePhotos/1_3_25 Picking.jpg' 
import img1425 from '../images/TimelinePhotos/1_4_25 Picking.jpg' 
import img12625 from '../images/TimelinePhotos/1_26_25Picking.jpg' 


import './OurProgress.css'

function Volunteer() {
  return (
    <>
      <div class="contentContainer">
        <center>
          <h1>Our Progress</h1>
          <h4>View our latest donations and what we've been up to recently</h4>
        </center>

        <br />
        <br />

        <Timeline position="alternate-reverse">

        <ProgressItem
          heading="Fruit Picking: January 26, 2025"
          firstline="30 lbs of lemons"
          secondline=""
          image={img12625}
        />
        <ProgressItem
          heading="Fruit Picking: January 4, 2025"
          firstline="122 lbs of lemons"
          secondline=""
          image={img1425}
        />
        <ProgressItem
          heading="Fruit Picking: January 3, 2025"
          firstline="30 lbs of lemons"
          secondline=""
          image={img1325}
        />
        
        <ProgressItem
          heading="Fruit Picking: December 27, 2024"
          firstline="132 lbs of lemons"
          secondline=""
          image={img1227}
        />

        <ProgressItem
          heading="Milestone: 1000 lbs donated"
          firstline="Reached December 23, 2024"
          secondline=""
          image=""
        />

        <ProgressItem
          heading="Fruit Picking: December 23, 2024"
          firstline="201 lbs of persimmons"
          secondline=""
          image={img1223}
        />

        <ProgressItem
          heading="Fruit Picking: December 21, 2024"
          firstline="80 lbs of lemons"
          secondline=""
          image=""
        />

        <ProgressItem
          heading="Fruit Picking: December 7, 2024"
          firstline="125 lbs of lemons"
          secondline=""
          image=""
        />

        <ProgressItem
          heading="Publication: November 14, 2024"
          firstline="FruitShare featured in Cambrian Living magazine"
          secondline=""
          image={img1114}
        />
        <ProgressItem
          heading="Fruit Picking: November 11, 2024"
          firstline="115 lbs of persimmons"
          secondline=""
          image={img1111}
        />

        <ProgressItem
          heading="Fruit Picking: October 13, 2024"
          firstline="55 lbs of pineapple guavas"
          secondline=""
          image={img1013}
        />

        <ProgressItem
          heading="Fruit Picking: September 8, 2024"
          firstline="35 lbs of pomegranates"
          secondline=""
          image={img0908}
        />

        <ProgressItem
          heading="Fruit Picking: August 15, 2024"
          firstline="10 lbs of apples"
          secondline=""
          image=""
        />

        <ProgressItem
          heading="Fruit Picking: August 14, 2024"
          firstline="50 lbs of lemons"
          secondline=""
          image={img0814}
        />

        <ProgressItem
          heading="Fruit Picking: August 13, 2024"
          firstline="10 lbs of oranges"
          secondline=""
          image=""
        />

        <ProgressItem
          heading="Fruit Picking: August 13, 2024"
          firstline="90 lbs of grapes"
          secondline=""
          image=""
        />

        <ProgressItem
          heading="Fruit Picking: August 12, 2024"
          firstline="50 lbs of plums"
          secondline=""
          image={img08122}
        />

        <ProgressItem
          heading="Fruit Picking: August 12, 2024"
          firstline="32 lbs of plums"
          secondline=""
          image={img0812}
        />

        <ProgressItem
          heading="Fruit Picking: August 6, 2024"
          firstline="95 lbs of plums, lemons, and oranges"
          secondline=""
          image={img0806}
        />

        <ProgressItem
          heading="Fruit Picking: July 15, 2024"
          firstline="15 lbs of plums"
          secondline=""
          image={img0715}
        />

        <ProgressItem
          heading="Fruit Picking: June 30, 2024"
          firstline="25 lbs of oranges"
          secondline=""
          image=""
        />

        <ProgressItem
          heading="Fruit Picking: June 30, 2024"
          firstline="25 lbs of apricots"
          secondline=""
          image={img0630}
        />

        <ProgressItem
          heading="Founding: April 2024"
          firstline="FruitShare is founded"
          secondline=""
          image=""
        />


  </Timeline>


      </div>
    </>
  )
}

export default Volunteer