import React from 'react';

import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';

import './ProgressItem.css';

const ProgressItem = ({ heading, firstline, secondline, image }) => {
  if(image === "") {
    return (
    
        <TimelineItem>
          <TimelineSeparator>
            <TimelineDot />
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent>
            <h4><strong>{heading}</strong></h4>
            <h4>{firstline}</h4>
            <h4>{secondline}</h4>
          </TimelineContent>
        </TimelineItem>
      );
  }
  else {
    return (
    
        <TimelineItem>
          <TimelineSeparator>
            <TimelineDot />
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent>
            <h4><strong>{heading}</strong></h4>
            <h4>{firstline}</h4>
            <h4>{secondline}</h4>
            <div classname="timelineImageContainer">
                <img src={image} alt="" className="timelineImage" />
            </div>
          </TimelineContent>
        </TimelineItem>
      );
  }
};

export default ProgressItem;
