import React from 'react';
import "./Footer.css";
import { Link } from 'react-router-dom';
const Footer = () => {
    return (
        <div className='footer'>
        <center>
            
            <h3 className='footerTitle'>FruitShare</h3>
            <h5>Copyright © 2024</h5>
            <div className='footerLinks'>
                <Link className='customLink' to='/PrivacyPolicy'>
                Privacy Policy{' '}{' '}|{' '}{' '}
                </Link>

                <Link className='customLink' to='/Homeowners'>
                For Homeowners{' '}{' '}|{' '}{' '}
                </Link>
                <Link className='customLink' to='mailto: contact.fruitshare@gmail.com'>
                For Volunteers{' '}{' '}|{' '}{' '}
                </Link>
                <Link className='customLink' to='/Blogs'>
                Blog{' '}{' '}|{' '}{' '}
                </Link>
                <Link className='customLink' to='/FAQ'>
                FAQ{' '}{' '}|{' '}{' '}
                </Link>
                <Link className='customLink' to='mailto: contact.fruitshare@gmail.com'>
                Contact
                </Link>
            </div>
        
        </center>
    </div>
    );
  };
  
  export default Footer;